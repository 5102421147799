import "@fontsource/manrope";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "10px",
  },
  palette: {
    type: "light",
    primary: {
      main: "#fb5e1f",
      secondary: "#FFEFE9",
    },
    secondary: {
      main: "#509eb9",
      contrastText: "#ffffff",
    },
    black: {
      main: "#191616",
      contrastText: "#ffffff",
    },
    white: {
      main: "#fff",
      contrastText: "#000",
    },
    blue: {
      main: "#457584",
    },
    lightBlue: {
      main: "#BFDDE8",
    },
    grey: {
      100: "#B4B4B4",
    },
    lightGray: {
      main: "#E9EDEE",
    },
    Orange: {
      main: "#FB5E1F",
      contrastText: "#ffffff",
    },
    lightOrange: {
      main: "#FFEFE9",
      contrastText: "#000",
    },
    darkBlue: {
      main: "#234853",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Manrope",
    h1: {
      fontSize: "3.25rem", // 52px
      fontWeight: 600,
    },
    h2: {
      fontSize: "2.2rem", //  40px
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem", //   19px
      fontWeight: 500,
    },
    p: {
      fontSize: "1.125rem", //  18px
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 600,
          borderRadius: "100px",
          textTransform: "capitalize",
          boxShadow: "none",
          minHeight: "54px",
          padding: "0 24px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            "-4px -4px 8px -6px rgba(98, 98, 98, 0.04), 0px 8px 16px -4px rgba(98, 98, 98, 0.06)",
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        // maxWidth: 'xl'
      },
    },
    MuiTextField: {
      defaultProps: { size: "small" },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "#F3F3F3",
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "4px",
          fontWeight: 500,
          color: "#191616",
          "& .MuiFormLabel-asterisk": {
            color: "#FB5E1F",
          },
        },
      },
    },
  },
});

export default theme;
